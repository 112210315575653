@import './vars.scss';

body {
  background-color: #f2f7ff;
}

a {
  text-decoration: none !important;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: inherit;
}

a.ant-btn {
  padding-top: initial;
  line-height: initial;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
  font-weight: 400;
}

ul,
ol {
  margin: 0;
  padding: 0;
}
figure {
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.readmoreBtn {
  background: none;
  border: none;
  padding: 0;
  color: #1780cd;
  font-weight: 700;
  margin-left: 5px;
}

// CMN STYLES
.cmnBtn {
  font-size: 11px;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 15px;
  height: 100%;
  font-weight: 700;
  margin-left: auto;
  background-color: #642d90;
  border: none;
  min-width: 106px;
  min-height: 36px;
  @include shadow1;
  @media (max-width: 991px) {
    min-width: auto;
  }
}
.bgPart {
  width: 60%;
  position: relative;
  @include gradientcolor();
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  figure {
    max-width: 60%;
  }
}
.grayBtn {
  font-size: 11px;
  color: #000000;
  font-weight: 700;
  background-color: #f1f1f1;
  border: none;
  border-radius: 5px;
  letter-spacing: 0.2px;
  padding: 5px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  min-width: 106px;
  @include shadow1;
  @media (max-width: 991px) {
    min-width: auto;
  }
}
.blueBtn {
  font-size: 11px;
  color: $themefontColor;
  font-weight: 700;
  background-color: $themecolor;
  border: none;
  border-radius: 5px;
  letter-spacing: 0.2px;
  padding: 5px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  min-width: 106px;
  @include shadow1;
  @media (max-width: 991px) {
    min-width: auto;
  }
}
.whiteBtn {
  font-size: 11px;
  color: #000000;
  font-weight: 700;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  letter-spacing: 0.2px;
  padding: 5px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  box-shadow: none;
  min-width: 106px;
  @media (max-width: 991px) {
    min-width: auto;
  }
  &.active {
    border: none;
    color: $themefontColor;
    @include shadow1;
    background-color: $themecolor;
  }
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: $themecolor !important;
  color: $themefontColor !important;
  img {
    filter: brightness(0) invert(1);
  }
}
.icon-wrp {
  display: inline-flex;
  margin-right: 8px;
  margin-top: -3px;
}
.ant-dropdown {
  min-width: 130px !important;
  .ant-dropdown-menu-item {
    font-size: 12px;
    color: #000000;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      border-bottom: 1px solid #7070700d;
    }
    span {
      display: flex;
      align-items: center;
    }
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: #f5f5f500;
    color: $themecolor;
    a {
      color: $themecolor;
    }
    .fillcss {
      fill: $themecolor;
    }
  }
}
.bgthemecolor {
  fill: $themecolor;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  position: relative;
  top: -5px;
}
.noData-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eef2f6;
  border-radius: 5px;
  flex-direction: column;
  padding: 20px;
  min-height: 20vh;
  height: auto;
  h4 {
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    color: #000000;
  }
}

.buttonCheckGrp {
  .ant-checkbox {
    display: none;
  }
  .ant-checkbox-wrapper {
    font-size: 14px;
    color: #000000;
    background-color: #f1f1f1;
    border-radius: 5px;
    min-height: 44px;
    border: 1px solid #ff000000;
    padding: 5px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 0;
    &.ant-checkbox-wrapper-checked {
      border: 1px solid $themecolor;
    }
    @media (max-width: 1440px) {
      min-height: 40px;
    }
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 12px;
  }
}
.eqwidth {
  .ant-checkbox-group {
    width: 100%;
    display: flex;
  }
}

// status buttons
.stdone,
.checkin {
  color: #0bae36;
}
.checkout,
.stpending {
  color: #ff1616;
}
.stinprogress {
  color: #ff8800;
}
.streopen {
  color: #ff1616;
}
// status buttons

.mb-30 {
  margin-bottom: 30px;
}

// CMN STYLES

// ...........................SIGNIN.............................

.container-wrp {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.signin-wrp {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.bgPart {
  width: 60%;
  position: relative;
  @include gradientcolor();
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  figure {
    max-width: 60%;
  }
}
.formPart {
  position: relative;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 560px;
  width: 40%;
  background-color: #fff;
  h1 {
    font-size: 39px;
    color: #000000;
    font-weight: 700;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
  h6 {
    color: #00000070;
    font-size: 20px;
    margin-bottom: 60px;
    @media (max-width: 1430px) {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background-color: #f6f6f6;
    border: 1px solid #d9d9d900;
  }
  .ant-checkbox + span {
    font-size: 15px;
    color: #00000070;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $themecolor;
    border-color: $themecolor;
  }
  .form-wrap {
    width: 100%;
    max-width: 380px;
    margin: auto;
  }
}
.sign-field {
  input {
    border: none;
    background: #f6f6f6;
    border-radius: 5px;
    padding: 13px 13px;
    font-size: 18px;
  }
  .ant-input-affix-wrapper {
    border: none;
    background: #f6f6f6;
    border-radius: 5px;
    padding: 13px 13px;
    font-size: 18px;
  }
}

.continuebtn {
  font-size: 20px;
  color: #ffffff;
  border-radius: 5px;
  margin-top: 30px;
  padding: 12px 13px;
  height: auto;
  line-height: normal !important;
  border: none;
  text-transform: uppercase;
  @include gradientcolor();
}

.ant-btn.continuebtn {
  &:hover,
  &:focus,
  &:active {
    @include gradientcolor();
    color: #ffffff;
  }
}

.forgotlink {
  font-size: 15px;
  color: #00000070;
}

.ant-form-item-has-error .ant-input {
  background-color: #f6f6f6 !important;
}
.ant-form-item-explain.ant-form-item-explain-error {
  color: #fff;
  background: #f30;
  padding: 2px 12px;
  border-radius: 5px;
  display: inline-flex;
  width: auto;
  margin-top: 5px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: -7px;
    border-bottom: solid 7px #f30;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent;
  }
}
input:-internal-autofill-selected {
  background-color: #f6f6f6 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f6f6f6 inset !important;
  box-shadow: 0 0 0 30px #f6f6f6 inset !important;
}
// ...........................SIGNIN.............................

// ...........................FORGOT PASSWORD.............................

.forgotpassword-wrp {
  h1 {
    margin-bottom: 10px;
  }
  .continuebtn {
    margin-top: 0;
  }
}
.backbtn {
  background: #eeeeee70;
  border: none;
  width: 75px;
  height: 75px;
  border-radius: 15px;
  @include shadow1;
  position: relative;
  top: -80px;
  svg {
    width: 22px;
  }
  @media (max-width: 1430px) {
    width: 60px;
    height: 60px;
    svg {
      width: 15px;
    }
  }
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
    position: initial;
    border-radius: 10px;
    margin-bottom: 25px;
    svg {
      width: 10px;
    }
  }
}

// ...........................FORGOT PASSWORD.............................

// ...........................HOTEL MANAGEMENT.............................

header {
  background-color: $themecolor;
  padding: 8px 20px;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 111;
}
.headericons-grp {
  & > ul {
    display: flex;
    & > li {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-left: 25px;
    }
  }
  .notification-wrp {
    .notificationCount {
      font-size: 8px;
      color: #ffffff;
      background-color: #ffffff;
      position: absolute;
      top: 0px;
      left: 7px;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      width: 10px;
      height: 10px;
      border: 2px solid #522e90;
    }
  }
}
.logo {
  height: 50px;
  text-align: left;
  display: flex;
  align-items: center;
}
.notificationPop {
  &.ant-btn {
    background: #1890ff00 !important;
    border-color: #1890ff00 !important;
    text-shadow: none !important;
    box-shadow: none !important;
    padding: 0;
    line-height: initial;
    font-size: initial;
    height: auto;
    &:hover img,
    &:focus img,
    &:active img {
      filter: brightness(1) invert(0);
    }
  }
}

.notificationDropdown {
  border-radius: 3px;
  overflow: hidden;
  @include shadow1();
  ul {
    width: 320px;
    max-width: 100%;
    max-height: 355px;
    overflow-y: auto;
    li {
      display: flex;
      font-size: 12px;
      color: #5a5a5a;
      border-bottom: 1px solid #00000012;
      padding: 15px 20px;
      figure {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 20px;
        flex: none;
        img {
          border-radius: 50%;
        }
      }
      span {
        display: block;
        font-size: 10px;
        color: #a4a4a5;
        margin-top: 5px;
      }
      &:last-child {
        border-bottom: none;
      }
      &.new {
        background-color: #1680cd24;
      }
    }
  }
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-content > .ant-popover-arrow {
    right: 6px;
  }
}
.headerUser {
  display: flex;
  align-items: center;
  figure {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .ant-dropdown-link {
    font-size: 19px;
    color: #ffffff;
    font-weight: 700;
    margin-left: 15px;
    display: flex;
    align-items: center;
    .anticon {
      font-size: 12px;
    }
  }
}
.powered-wrp {
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-top: 50px;
  h6 {
    font-size: 12px;
    color: #242424;
  }
}
.sideMenu {
  box-shadow: 0px 3px 6px 0 #0000000d;
  width: 306px;
  background: #fff;
  padding: 30px 0px 30px 20px;
  height: calc(100vh - 66px);
  overflow-x: auto;
  position: fixed;
  top: 66px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1430px) {
    padding: 28px 0px 28px 20px;
  }
  .ant-menu-item {
    padding: 5px 0;
    font-size: 14px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: auto !important;
    margin: 0 !important;
    padding-left: 16px !important;
    img {
      margin-top: -5px;
      margin-right: 20px;
      max-width: 16px;
    }
  }
  .ant-menu-sub {
    padding-left: 20px !important;
  }
  .ant-menu-submenu-title {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: auto !important;
    padding-left: 16px !important;
    img {
      margin-top: -5px;
      margin-right: 20px;
    }
  }
  .ant-layout-sider {
    width: 100% !important;
    min-width: 100% !important;
    background: #ffffff;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $themecolor;
    color: $themefontColor !important;
    a {
      color: $themefontColor !important;
    }
    img {
      filter: brightness(0) invert(1);
    }
  }
  .ant-menu-item-selected::after {
    display: none !important;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #a4a4a538;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    border-radius: 3px;
  }
}
.logoutbtn {
  font-size: 19px;
  color: #78787d;
  background: none;
  border: none;
  position: absolute;
  bottom: 0;
  left: 20px;
  img {
    margin-right: 20px;
  }
}
.mainContent {
  padding: 66px 0px 0px 306px;
  .mainContent-in {
    padding: 30px 30px 25px 30px;
  }
}
.hotelLogo {
  border-radius: 50%;
}
.Pagenamecard-wrp {
  background-image: url('../assets/backgroundimages/pageheadbglayer.png');
  background-size: cover;
  background-color: $themecolor;
  display: flex;
  border-radius: 5px;
  justify-content: space-between;
  padding: 0px 15px 0px 30px;
  height: 110px;
  align-items: center;
  margin-bottom: 30px;
  @include shadow1();
  h1 {
    font-size: 22px;
    color: #ffffff;
    font-weight: 700;
  }

  .ant-breadcrumb > span {
    font-size: 14px;
    color: #ffffff8a;
  }
  .ant-breadcrumb-separator {
    color: #ffffff8a;
    margin: 0 3px;
  }
  .ant-breadcrumb-link {
    color: #ffffff;
  }
  figure {
    display: flex;
    align-self: flex-end;
  }
}
.tablefilter-wrp {
  margin-bottom: 30px;
  @media (max-width: 1430px) {
    .searchbox .ant-input-group .ant-input {
      height: 36px;
    }
    .cmnSelect-form {
      .ant-select-selector {
        height: 36px !important;
      }
      .ant-picker {
        height: 36px;
      }
    }
  }
  .cmnBtn {
    max-height: 41px;
  }
}
.searchbox {
  .ant-input-group {
    border-radius: 5px;
    overflow: hidden;
    @include shadow1();
  }
  .ant-input-group .ant-input {
    height: 40px;
    border: none;
    font-size: 11px;
    color: #78787d;
  }
  .ant-input-search-button {
    position: absolute;
    right: 6px;
    top: 0;
    bottom: 0;
    margin: auto;
    border: none;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon {
      font-size: 15px;
    }
  }
}
.cmnSelect-form {
  .ant-select {
    width: 100%;
    left: 0;
    z-index: 11;
  }
  .headericons-grp {
    & > ul {
      display: flex;
      & > li {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-left: 25px;
      }
    }
    .notification-wrp {
      .notificationCount {
        font-size: 8px;
        color: #ffffff;
        background-color: #ffffff;
        position: absolute;
        top: 0px;
        left: 7px;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        width: 10px;
        height: 10px;
        border: 2px solid #522e90;
      }
    }
  }
  .logo {
    height: 50px;
    width: 110px;
    text-align: left;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: left;
    }
  }
  .notificationPop {
    &.ant-btn {
      background: #1890ff00 !important;
      border-color: #1890ff00 !important;
      text-shadow: none !important;
      box-shadow: none !important;
      padding: 0;
      line-height: initial;
      font-size: initial;
      height: auto;
      &:hover img,
      &:focus img,
      &:active img {
        filter: brightness(1) invert(0);
      }
    }
  }

  .notificationDropdown {
    border-radius: 3px;
    overflow: hidden;
    @include shadow1();
    ul {
      width: 320px;
      max-width: 100%;
      max-height: 355px;
      overflow-y: auto;
      li {
        display: flex;
        font-size: 12px;
        color: #5a5a5a;
        border-bottom: 1px solid #00000012;
        padding: 15px 20px;
        figure {
          width: 35px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: 20px;
          flex: none;
          img {
            border-radius: 50%;
          }
        }
        span {
          display: block;
          font-size: 10px;
          color: #a4a4a5;
          margin-top: 5px;
        }
        &:last-child {
          border-bottom: none;
        }
        &.new {
          background-color: #1680cd24;
        }
      }
    }
    .ant-popover-inner-content {
      padding: 0;
    }
    .ant-popover-content > .ant-popover-arrow {
      right: 6px;
    }
  }
  .headerUser {
    display: flex;
    align-items: center;
    figure {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .ant-dropdown-link {
      font-size: 19px;
      color: #ffffff;
      font-weight: 700;
      margin-left: 15px;
      display: flex;
      align-items: center;
      .anticon {
        font-size: 12px;
      }
    }
  }
  .powered-wrp {
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-top: 50px;
    h6 {
      font-size: 12px;
      color: #242424;
    }
  }
  .sideMenu {
    box-shadow: 0px 3px 6px 0 #0000000d;
    width: 306px;
    background: #fff;
    padding: 30px 0px 30px 20px;
    height: calc(100vh - 66px);
    overflow-x: auto;
    position: fixed;
    top: 66px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1430px) {
      padding: 28px 0px 28px 20px;
    }
    .ant-menu-item {
      padding: 5px 0;
      font-size: 14px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      height: auto !important;
      margin: 0 !important;
      padding-left: 16px !important;
      img {
        margin-top: -5px;
        margin-right: 20px;
        max-width: 16px;
      }
    }
    .ant-menu-sub {
      padding-left: 20px !important;
    }
    .ant-menu-submenu-title {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: auto !important;
      padding-left: 16px !important;
      img {
        margin-top: -5px;
        margin-right: 20px;
      }
    }
    .ant-layout-sider {
      width: 100% !important;
      min-width: 100% !important;
      background: #ffffff;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: $themecolor;
      color: $themefontColor !important;
      a {
        color: $themefontColor !important;
      }
      img {
        filter: brightness(0) invert(1);
      }
    }
    .ant-menu-item-selected::after {
      display: none !important;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #a4a4a538;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a6a6a6;
      border-radius: 3px;
    }
  }
  .logoutbtn {
    font-size: 19px;
    color: #78787d;
    background: none;
    border: none;
    position: absolute;
    bottom: 0;
    left: 20px;
    img {
      margin-right: 20px;
    }
  }
  .mainContent {
    padding: 66px 0px 0px 306px;
    .mainContent-in {
      padding: 30px 30px 25px 30px;
    }
  }
  .hotelLogo {
    border-radius: 50%;
  }
  .Pagenamecard-wrp {
    background-image: url('../assets/backgroundimages/pageheadbglayer.png');
    background-size: cover;
    background-color: $themecolor;
    display: flex;
  }
  .ant-select-selector {
    height: 40px !important;
    display: flex;
    align-items: center;
    border: none !important;
    border-radius: 5px !important;
    color: #78787d;
    font-size: 11px;
    padding: 0 20px !important;
    @include shadow1();
  }
  .ant-picker {
    border-radius: 5px;
    overflow: hidden;
    @include shadow1();
    border: none;
    height: 40px;
    width: 100%;
    input {
      color: #78787d;
      font-size: 11px;
      &::-webkit-input-placeholder {
        color: #78787d;
      }
      &:-ms-input-placeholder {
        color: #78787d;
      }
      &::placeholder {
        color: #78787d;
      }
    }
  }
}

.adduserbtn {
  @include shadow1();
  border: none;
  border-radius: 5px;
  background-color: #fff;
  height: 40px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 40px;
  @media (max-width: 1430px) {
    height: 36px;
  }
}

.table-wrp {
  @include shadow1;
  .ant-table {
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;

    background: none;
    table {
      border-collapse: collapse;
      border-radius: 0;
    }
  }
  .ant-table-thead > tr > th {
    color: #000000;
    font-weight: 700;
    background: #e8e8e8;
    border: none;
    font-size: 12px;
    padding: 8px 10px;
    height: 50px;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  .ant-table-tbody > tr > td {
    font-size: 12px;
    color: #000000;
    background: #ffffff;
    padding: 5px 10px;
    border-bottom: 1px solid #00000012;
    height: 48px;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  .ant-table-body {
    overflow-y: auto !important;
    table {
      -webkit-border-vertical-spacing: 5px !important;
    }
    tr {
      box-shadow: 0px 10px 30px #868e961a;
      &.disabled {
        td {
          color: #dbdbdb;
        }
        .viewlink {
          color: #dbdbdb;
        }
        h6 {
          color: #dbdbdb;
        }
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: #a4a4a538;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a6a6a6;
      border-radius: 3px;
    }
  }

  .ant-table-cell-scrollbar {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .ant-rate {
    font-size: 14px;
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
  }
}
.viewlink {
  font-size: 12px;
  color: #1680cd;
  font-weight: 700;
  text-decoration: underline !important;
  margin: 0 6px;
  &.disabled {
    color: #dbdbdb;
  }
}
.viewlink {
  font-size: 12px;
  color: #1680cd;
  font-weight: 700;
  text-decoration: underline !important;
  margin: 0 6px;
  &.disabled {
    color: #dbdbdb;
  }
}
.activeStatus {
  color: #0bae36;
  font-weight: 500;
  
  .ant-select-selector {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  .ant-select-arrow {
    right: 0 !important;
  }
}
.inactiveStatus {
  color: #ff1616;
  font-weight: 500;
  
  .ant-select-selector {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  .ant-select-arrow {
    right: 0 !important;
  }
}
.tableactionbtn-grp {
  button {
    background: none;
    border: none;
  }

  button:disabled {
    cursor: not-allowed;
    img {
      filter: grayscale(4%) contrast(2%);
      -webkit-filter: grayscale(4%) contrast(2%);
      -moz-filter: grayscale(4%) contrast(2%);
    }
  }
}
.tableuser {
  display: flex;
  align-items: center;
  figure {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    // overflow: hidden;
    flex: none;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  span {
    font-size: 12px;
    color: #000000;
  }
}
.sa-tableuser {
  figure {
    border: 1px solid #efe9f4;
  }
}
.userCard-wrp {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 30px 30px;
  position: relative;
  margin-bottom: 30px;
  border-radius: 10px;
  @include shadow1;
  figure {
    position: relative;
    margin-right: 30px;
  }
  .figin {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    overflow: hidden;
    @include shadow1;
  }
  span {
    display: block;
    width: 12px;
    height: 12px;
    background-color: #0abe58;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: -3px;
  }
  .usercaption {
    h3 {
      font-size: 36px;
      color: #000000;
    }
    h6 {
      font-size: 14px;
      color: #00000047;
    }
  }
  .iconbtn {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}
.iconbtn {
  background: #eeeeee;
  border-radius: 4px;
  border: none;
  width: 30px;
  height: 30px;
  @include shadow1;
}

.countcard-wrp {
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 25px;
  margin-bottom: 30px;
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('../assets/backgroundimages/countcardlayer.png');
    background-size: cover;
  }
  &.Gr-brown {
    background: #b2aa8e;
    background: -moz-linear-gradient(top, #b2aa8e 0%, #706335 100%);
    background: -webkit-linear-gradient(top, #b2aa8e 0%, #706335 100%);
    background: linear-gradient(to bottom, #b2aa8e 0%, #706335 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b2aa8e', endColorstr='#706335',GradientType=0 ); /* IE6-9 */
  }
  &.Gr-green {
    background: #03b5aa;
    background: -moz-linear-gradient(top, #03b5aa 0%, #09605a 100%);
    background: -webkit-linear-gradient(top, #03b5aa 0%, #09605a 100%);
    background: linear-gradient(to bottom, #03b5aa 0%, #09605a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03b5aa', endColorstr='#09605a',GradientType=0 ); /* IE6-9 */
  }
  &.Gr-blue {
    background: #6d9dc5;
    background: -moz-linear-gradient(top, #6d9dc5 0%, #336691 100%);
    background: -webkit-linear-gradient(top, #6d9dc5 0%, #336691 100%);
    background: linear-gradient(to bottom, #6d9dc5 0%, #336691 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6d9dc5', endColorstr='#336691',GradientType=0 ); /* IE6-9 */
  }
  div {
    position: relative;
    z-index: 1;
  }
  h4 {
    font-size: 36px;
    color: #ffffff;
    font-weight: 700;
  }
  h6 {
    font-size: 12px;
    color: #ffffffb0;
  }
  figure {
    position: relative;
    z-index: 1;

    @include shadow1;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
  }
}

.cmnCard-wrp {
  padding: 25px 25px;
  background-color: #ffffff;
  border-radius: 5px;
  @include shadow1;
  .cardHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    h3 {
      font-size: 20px;
      color: #000000;
    }
  }
}

.chart-wrp {
  position: relative;
  max-height: 106px;
  width: 100%;
  max-width: 106px;
  flex: none;
  span {
    font-size: 17px;
    color: #00285e;
    font-weight: 700;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
  }
}
.ActivityCard-wrp {
  h6 {
    font-size: 12px;
    color: #b5b5b8;
    text-align: center;
    margin: 0 0 0 10px;
  }
}
.cmnModal {
  width: 580px !important;
  max-width: 95%;

  .ant-modal-header {
    border-bottom: 1px solid #f0f0f000;
    padding: 25px 24px;
    padding-bottom: 5px;
    .ant-modal-title {
      color: #000000;
      font-size: 22px;
      font-weight: 700;
    }
  }
  .ant-modal-close {
    background: #f5f5f5;
    border-radius: 5px;
    width: 26px;
    height: 26px;
    margin: 15px 20px;
    top: 5px;
    @include shadow1;
    .ant-modal-close-x {
      width: 100%;
      height: 100%;
      line-height: initial;
    }
  }
  .ant-modal-content {
    border-radius: 5px;
    overflow: hidden;
  }
  .modalFooter {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 5px;
  }
}
.successModal {
  .ant-modal-content {
    border-radius: 5px;
  }
  .ant-modal-close {
    background: #f5f5f5;
    border-radius: 5px;
    width: 26px;
    height: 26px;
    margin: 15px 20px;
    @include shadow1;
    .ant-modal-close-x {
      width: 100%;
      height: 100%;
      line-height: initial;
    }
  }
}
.deleteModal {
  &.ant-modal {
    width: 430px !important;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-close {
    display: none;
  }
}
.deletetext-wrp {
  h6 {
    font-size: 13px;
    color: #000000;
    margin-top: 15px;
  }
  h2 {
    color: #000000;
    font-size: 22px;
    font-weight: 700;
  }
}
.successtext-wrp {
  text-align: center;
  padding: 30px 0;
  h6 {
    font-size: 13px;
    color: #000000;
    margin-top: 20px;
  }
}

.addHotelmodal {
  width: 1230px !important;
  max-width: 95%;
}

.imageUpload-wrp {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  @media (max-width: 1440px) {
    margin-bottom: 20px;
  }
  .upload-figin {
    position: relative;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @include shadow1;
    background: #efefef;
    img {
      max-width: 100%;
      max-height: 75%;
    }
    @media (max-width: 1440px) {
      width: 130px;
      height: 130px;
    }
    @media (max-width: 767px) {
      width: 100px;
      height: 100px;
    }
  }
  figure {
    margin-right: 30px;
    position: relative;
    @media (max-width: 767px) {
      margin-right: 16px;
    }
    .removebtn {
      @include shadow1;
      background-color: #f44336;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 5px;
      right: 5px;
      border: none;
      @media (max-width: 767px) {
        width: 16px;
        height: 16px;
        img {
          width: 8px;
        }
      }
    }
  }
}

.uploadbtn-wrp {
  button {
    background-color: $themecolor;
    font-size: 11px;
    color: $themefontColor;
    font-weight: 700;
    border: none;
    padding: 9px 22px;
    border-radius: 5px;
    margin-bottom: 15px;
    letter-spacing: 0.2px;
    @include shadow1;
  }
  p {
    font-size: 12px;
    color: #00000073;
  }
}

.cmn-input {
  .ant-form-item {
    @media (max-width: 1440px) {
      margin-bottom: 16px;
    }
  }
  .ant-form-item-label {
    color: #00000082;
    font-size: 12px;
    padding-bottom: 3px;
  }
  input {
    border: none;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 11px 13px;
    font-size: 14px;
    color: #000000;
    &::-webkit-input-placeholder {
      color: #78787d;
    }
    &:-ms-input-placeholder {
      color: #78787d;
    }
    &::placeholder {
      color: #78787d;
    }
    @media (max-width: 1440px) {
      padding: 10px 13px;
      font-size: 13px;
    }
  }

  .ant-input-affix-wrapper {
    border: none;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 11px 13px;
    font-size: 14px;
    &:-ms-input-placeholder {
      color: #78787d;
    }
    &::placeholder {
      color: #78787d;
    }
    @media (max-width: 1440px) {
      padding: 10px 13px;
      font-size: 13px;
    }
  }
  textarea {
    border: none;
    padding-left: 0;
    background: #f6f6f6;
    border-radius: 8px;
    &::-webkit-input-placeholder {
      color: #78787d;
    }
    &:-ms-input-placeholder {
      color: #78787d;
    }
    &::placeholder {
      color: #78787d;
    }
  }

  .ant-input-affix-wrapper {
    border: none;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 11px 13px;
    font-size: 14px;
  }
  .ant-select {
    width: 100%;
  }
  .ant-select-selector {
    height: 44px !important;
    display: flex;
    align-items: center;
    border: none !important;
    border-radius: 8px !important;
    color: #000000;
    font-size: 14px;
    padding: 0 20px !important;
    background: #f6f6f6 !important;
    @media (max-width: 1440px) {
      height: 40px !important;
      font-size: 13px;
    }
  }
  .ant-picker {
    padding: 0;
    background: #f6f6f6;
    border: none;
    border-radius: 8px;
    width: 100%;
    input {
      padding-right: 5px;
    }
    .ant-picker-suffix {
      margin-right: 10px;
    }
    .ant-picker-clear {
      margin-right: 10px;
    }
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
}
.contact-input {
  .ant-form-item-label {
    color: #00000082;
    font-size: 12px;
    padding-bottom: 3px;
  }
  input {
    border: none;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 11px 13px;
    font-size: 14px;
    color: #000000;
  }
  .ant-input-affix-wrapper {
    border: none;
    background: #f6f6f6;
    border-radius: 8px;
    padding: 11px 13px;
    font-size: 14px;
    color: #000000;
  }
  .ant-input-group-addon {
    background-color: #f6f6f6;
    border: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  span.ant-select-selection-item {
    font-size: 12px !important;
    font-weight: 700;
  }
  .ant-select-selector {
    border-right: 1px solid #dddddd !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }
}

.viewall-link {
  font-size: 10px;
  color: #2e4154;
  text-transform: uppercase;
  border-radius: 4px;
  border: 1px solid #9daebf;
  padding: 0px 16px;
  line-height: 26px;
  height: 26px;
}
.progressbarContainer {
  max-height: 255px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 3px;
  @media (max-width: 767px) {
    max-height: initial;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #a4a4a538;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    border-radius: 3px;
  }
}
.progressbar-wrp {
  margin: 25px 0 25px;
  h5 {
    font-size: 12px;
    color: #747474;
    span {
      float: right;
    }
  }
}
.medal-wrp {
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ebebeb;
}

.SubscriptionCard-wrp {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .cardHead {
    margin-bottom: 35px;
  }
  .ActivityCard-wrp {
    padding: 0;
    box-shadow: none;
    .cardHead {
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }
  .ActivityCard-wrp h6 {
    margin: 15px 0px 10px 0px;
  }
  .chart-wrp {
    margin: auto;
  }
}
.hotelmgmt-wrp {
  .SubscriptionCard-wrp {
    height: auto;
  }
}

// ...........................HOTEL MANAGEMENT.............................

// ...........................SUBSCRIPTION.............................

.subscriptionBox-wrp {
  max-height: 556px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 767px) {
    max-height: initial;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #a4a4a538;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    border-radius: 3px;
  }
}
.subscriptionBox {
  text-align: center;
  background-color: #ffffff;
  @include shadow1;
  margin-bottom: 30px;

  &.goldBox {
    .subscriptionBox-head {
      background-color: #dab456;
    }
  }
  &.silverBox {
    .subscriptionBox-head {
      background-color: #b4b7bb;
    }
  }
  &.bronzeBox {
    .subscriptionBox-head {
      background-color: #caa16d;
    }
  }
  .subscriptionBox-head {
    background-color: $themecolor;
    @include shadow1;

    h3 {
      font-size: 18px;
      color: #ffffff;
      font-weight: 700;
      text-transform: uppercase;
      padding: 20px 10px;
      position: relative;
      .anticon {
        position: absolute;
        right: 6px;
        font-size: 14px;
        top: 6px;
      }
    }
  }
  .subscriptionBox-body {
    padding: 20px;
    h4 {
      font-size: 24px;
      color: #000000;
      font-weight: 700;
      margin: 15px 0 5px 0;
    }
    h5 {
      font-size: 12px;
      color: #8b8b8b;
      margin-bottom: 20px;
    }
  }
  .subscriptionBoxbtn-wrp {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cmnBtn {
      min-width: auto;
      width: 48%;
      padding: 0;
      margin: 0;
    }
  }
}
.cmnCollapse-wrp {
  .ant-collapse {
    border: none;
    background-color: #f1f1f1;
    border-radius: 5px;
  }
  .ant-collapse-content {
    background-color: #f1f1f1;
    border-top: none;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    position: relative;
    top: -4px;
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
    font-size: 14px;
    color: #000000;

    &.ant-collapse-item-active {
      & > .ant-collapse-header {
        background-color: #bacfdf;
      }
    }
    .subCollapse {
      .ant-collapse-item-active {
        .ant-collapse-header {
          background-color: #bacfdf00;
        }
      }
    }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 0 0 20px;
  }
  .ant-checkbox-inner {
    width: 19px;
    height: 19px;
    border: 1px solid #e2e3e4;
  }
  .ant-checkbox-inner::after {
    left: 25%;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #642d90;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ffffff;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #642d90;
  }
  .ant-checkbox-checked::after {
    border-color: #642d90;
  }
}
.checkList {
  padding: 0px 16px 0px 20px;
  li {
    font-size: 14px;
    color: #000000;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.viewplansModal {
  width: 480px !important;
  h4 {
    font-size: 15px;
    color: #000000;
    font-weight: 700;
  }
  h5 {
    font-size: 20px;
    color: #642d90;
    font-weight: 700;
    margin: 15px 0 30px;
  }
}
.tickList {
  li {
    font-size: 12px;
    color: #8b8b8b;
    margin-bottom: 15px;
    display: flex;
    align-items: baseline;
    img {
      margin-right: 15px;
    }
  }
}

// ...........................SUBSCRIPTION.............................

// ...........................DEPARTMENT.............................

.tablepopover {
  &.ant-btn {
    background: #1890ff00 !important;
    border-color: #1890ff00 !important;
    text-shadow: none !important;
    box-shadow: none !important;
    padding: 5px;
    &:hover img,
    &:focus img,
    &:active img {
      filter: brightness(1) invert(0);
    }
  }
  &.ant-popover-open {
    img {
      transform: rotateY(180deg);
    }
  }
  .customSwitch {
    margin-left: auto;
  }
}
.subtablepopover {
  padding: 0 5px;
  position: absolute;
  right: 6px;
  margin-top: -2px;
}
.tableDropdown {
  ul {
    min-width: 280px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: #000000;
      border-bottom: 1px solid #00000012;
      padding: 12px 30px;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .ant-popover-inner-content {
    padding: 0;
  }
}

.addnewLink {
  font-size: 11px;
  color: #000000;
  font-weight: 700;
  border: none;
  display: flex;
  align-items: center;
  box-shadow: none;
  padding: 0;
  &:active,
  &:focus,
  &:hover {
    color: #000000 !important;
    background: none !important;
  }
  .anticon {
    background-color: #642d90;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
  }
}
.subdepimg {
  width: 26px;
  height: 26px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  img {
    width: 100%;
    height: 100%;
  }
}
.subdepartmentCollapse-wrp {
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    top: 7.0005px;
  }
  .noSubservice {
    .ant-collapse-arrow {
      display: none !important;
    }
  }
  .ant-collapse {
    border: none;
    background-color: #f1f1f1;
    border-radius: 5px;
  }
  .ant-collapse-content {
    background-color: #f1f1f1;
    border-top: none;
  }
  .ant-collapse > .ant-collapse-item {
    font-size: 14px;
    color: #000000;
    border-bottom: 1px solid #0000000d;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 0 0 20px;
  }
  ul {
    padding: 0px 16px 0px 50px;
    li {
      font-size: 14px;
      color: #000000;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
    }
  }
}
// ...........................DEPARTMENT.............................

// ...........................MY PROFILE.............................

.profileCard {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 25px;
  @include shadow1();
  .profileMainDetails {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee2e;
    padding: 30px 20px;
    text-align: center;
    width: 260px;
    min-height: 500px;
    height: 100%;
    @media (max-width: 1430px) {
      width: 214px;
      min-height: 450px;
    }
    @media (max-width: 991px) {
      width: 100%;
      min-height: auto;
      margin-bottom: 20px;
      height: auto;
    }
    figure {
      width: 170px;
      height: 170px;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      @media (max-width: 1430px) {
        width: 150px;
        height: 150px;
      }
    }
    h4 {
      font-size: 25px;
      color: #000000;
      font-weight: 700;
      margin: 18px 0 8px 0;
    }
    h5 {
      font-size: 15px;
      color: #787878;
    }
    p {
      font-size: 13px;
      color: #787878;
    }
  }
  .profileSubDetails {
    border-radius: 5px;
    border: 1px solid #eeeeee;
    background-color: #eeeeee2e;
    padding: 30px 20px;
    height: 100%;
    overflow: hidden;
    ul + ul {
      position: relative;
      padding-top: 20px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100px;
        width: 1500px;
        height: 1px;
        background-color: #eeeeee;
      }
    }
    li {
      font-size: 15px;
      color: #000000;
      display: flex;
      margin-bottom: 20px;
      h6 {
        color: #00000082;
        width: 150px;
        flex: none;
        padding-right: 5px;
      }
      span {
        word-break: break-all;
      }
    }
  }
}

// ...........................MY PROFILE.............................

// RESPONSIVE
@media (max-width: 1430px) {
  .bgPart {
    width: 55%;
  }
  .formPart {
    width: 45%;
  }
  .formPart h1 {
    font-size: 34px;
  }
  .sign-field input {
    font-size: 16px;
    padding: 10px 13px;
  }
  .sign-field .ant-input-affix-wrapper {
    padding: 10px 13px;
    font-size: 16px;
  }
  .continuebtn {
    font-size: 16px;
    padding: 10px 13px;
    margin-top: 15px;
  }
  .forgotlink {
    font-size: 13px;
  }
  .formPart .ant-checkbox + span {
    font-size: 13px;
  }

  // HOTEL MANAGEMENT
  .sideMenu {
    width: 250px;
  }
  .sideMenu .ant-menu-submenu-title,
  .sideMenu .ant-menu-item {
    padding: 3px 0;
  }

  .mainContent {
    padding: 66px 0px 0px 250px;
    .mainContent-in {
      padding: 28px 30px 25px 30px;
    }
  }
  // HOTEL MANAGEMENT
}
@media (max-width: 1199px) {
  // HOTEL MANAGEMENT
  .countcard-wrp {
    padding: 18px 15px;
  }
  .cmnCard-wrp {
    padding: 18px 15px;
  }
  .chart-wrp {
    margin: auto;
  }
  // HOTEL MANAGEMENT
}

@media (max-width: 991px) {
  .formPart {
    padding: 20px 50px;
    min-width: 460px;
    width: 45%;
  }
  // HOTEL MANAGEMENT
  .ActivityCard-wrp h6 {
    margin: 10px 0px 0 0px;
  }
  .table-wrp {
    margin-bottom: 30px;
  }
  // HOTEL MANAGEMENT
}
@media (max-width: 767px) {
  .signin-wrp {
    display: block;
    height: auto;
    min-height: 100vh;
  }
  .bgPart {
    width: 100%;
  }
  .formPart {
    width: 100%;
  }
  .bgPart figure {
    max-width: 100%;
    img {
      max-width: 250px;
    }
  }
  .formPart {
    padding: 20px 25px;
    min-width: 100%;
    width: 45%;
  }
  .formPart h1 {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .sign-field input {
    font-size: 16px;
    padding: 10px 13px;
  }
  .sign-field .ant-input-affix-wrapper {
    font-size: 16px;
    padding: 10px 13px;
  }
  .continuebtn {
    font-size: 16px;
  }

  // DASHBOARD
  header {
    padding: 5px 0px;
    min-height: 56px;
  }
  .headericons-grp > ul > li {
    margin-left: 10px;
  }
  .headerUser {
    figure {
      width: 30px;
      height: 30px;
      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .ant-dropdown-trigger {
      .usernamespan {
        display: none;
      }
    }
  }
  .headerUser .ant-dropdown-link {
    font-size: 12px;
    margin-left: 0;
  }
  .mobmenuicon {
    .anticon {
      color: #fff;
    }
  }
  .mainContent {
    padding: 56px 0px 0px 0px;
  }
  .mainContent .mainContent-in {
    padding: 15px 15px 15px 15px;
  }
  .sideMenu {
    left: -250px;
    z-index: 111;
    top: 0;
    height: 100%;
    transition: left 0.3s;
    padding: 26px 0px 25px 15px;
    border-right: 1px solid #a6a6a6;
    &.open {
      left: 0px;
      transition: left 0.3s;
    }
  }
  .sideMenu .ant-menu-item {
    padding: 5px 0;
    font-size: 13px;
    padding-left: 9px !important;
    line-height: 30px !important;
  }
  button.menuclosebtn {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #1680cd;
    height: 25px;
    line-height: 10px;
    color: #fff;
  }
  .headericons-grp {
    .notification-wrp {
      img {
        width: 14px;
      }
    }
  }
  .logo-wrp span {
    font-size: 14px;
  }
  .logo-wrp span small {
    font-size: 12px;
  }
  .bookingoverviewCard-wrp {
    .cardHead {
      display: block;
    }
  }
  .filterSelect {
    margin-left: 0;
    margin-right: 5px;
  }
  .filterdatepicker {
    margin-left: 0;
    margin-right: 5px;
  }
  // DASHBOARD
}

// RESPONSIVE

.barchart-wrp {
  position: relative;
  width: 100%;
  max-width: 100%;
  flex: none;
  height: 345px;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
  @media (max-width: 767px) {
    height: auto;
  }
}

.filterSelect {
  margin-left: 5px;
  .ant-select {
    .ant-select-selector {
      border: 1px solid #9daebf40;
      border-radius: 4px;
      height: 28px;
      padding: 0 8px;
      line-height: 28px;
    }

    .ant-select-selection-item {
      font-size: 11px;
      color: #2e4154;
      line-height: 28px;
    }
  }
}
.filterdatepicker {
  margin-left: 5px;
  .ant-picker {
    height: 28px;
    border: 1px solid #9daebf40;
    border-radius: 4px;
    padding: 0 8px;
    line-height: 28px;
    .ant-picker-input > input {
      font-size: 11px;
      color: #2e4154;
      line-height: 28px;
      &::placeholder {
        color: #2e4154;
      }
    }
  }
}

.contact-number input {
  margin-left: -4px;
}
.contact-number span.ant-input-group-addon {
  border: 0;
}
.rc-virtual-list .rc-virtual-list-scrollbar {
  display: none !important;
}
.rc-virtual-list-holder {
  overflow: auto !important;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.maintenance-wrp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  padding: 10px;
  text-align: center;
  h1 {
    font-size: 37px;
    color: #584778;
    font-weight: 700;
    margin: 30px 0 20px 0;
    @media (max-width: 767px) {
      font-size: 25px;
    }
  }
  h3 {
    font-size: 17px;
    color: #a19cac;
    @media (max-width: 767px) {
      font-size: 15px;
    }
  }
}
.departmentcount-wrp {
  display: flex;
  align-items: center;
  .anticon {
    font-size: 16px;
    color: #642d90;
    cursor: pointer;
  }
  .ant-space-item {
    display: flex;
    align-items: center;
    margin-left: 6px;
  }
  .depcount {
    display: flex;
    align-items: center;
    min-width: 15px;
    justify-content: center;
    line-height: 12px !important;
  }
}
.productionToast-msg {
  position: fixed;
  z-index: 111;
  width: 100%;
  height: auto;
  padding: 20px 50px 20px 20px;
  border-radius: 0;
  top: 0%;
  left: 0;
  right: 0;
  margin: auto;
  color: #fff;
  background-color: #006f34;
  text-align: center;
  @media (max-width: 767px) {
    top: auto;
    bottom: 0%;
  }
}
.toastOpen {
  @media (min-width: 768px) {
    .App {
      padding-top: 64px;
    }
    .sideMenu {
      height: calc(100vh - 130px);
      top: 130px;
    }
  }
}
.editButonnSelect {
  .ant-select-selection-item {
    em {
      font-style: initial !important;
    }
    .option-actionsBtn {
      display: none;
    }
  }
}
.editButonn-dropdown {
  .ant-select-item-option-content {
    display: flex;
    em {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-style: initial !important;
    }
  }
  .ant-select-item:hover {
    .option-actionsBtn {
      opacity: 1;
      pointer-events: all;
    }
  }
}
.option-actionsBtn {
  opacity: 0;
  pointer-events: none;
  margin-left: auto;
  margin-top: -2px;
  button {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
  }
}
.plusBtn {
  background-color: #642d90;
  font-size: 13px;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  margin-top: 25px;
  margin-left: 9px;
  display: flex;
  height: 40px;
  align-items: center;
  width: 40px;
  justify-content: center;
  flex: none;
}
.group-input {
  display: flex;
  .ant-form-item,
  .ant-row {
    width: 100%;
  }
}

.customCheckbox {
  .ant-checkbox {
    top: -3px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #642d90;
    border-color: #f5f5f5;
  }
  .ant-checkbox + span {
    color: #642d90;
  }
  .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #f5f5f5;
  }
}

.checkbox-margin {
  @media (min-width: 768px) {
    margin-top: 30px;
  }
}

.ant-select-dropdown .ant-select-item-option-selected {
  font-weight: 500;
}
